import {getFormattedDate} from "@/util";
import dayjs from "dayjs";

/**
 * Fonctions de date dans un composant Vue.
 *
 * getReadableDate(date) : retourne la date dans un format lisible (01 Janvier 1970)
 *
 * getFormattedDate(date) : retourne au format abrégé (01/01/1970)
 * @type {{methods: {getReadableDate(*=): *, getFormattedDate(*=): *}}}
 */
export const DateUtilMixin = {
    methods: {
        /**
         * Retourne au format abrégé (01/01/1970)
         * @param date
         * @returns {null|string|*}
         */
        getFormattedDate(date) {
            return getFormattedDate(date);
        },
        /**
         * Retourne la date dans un format lisible (01 Janvier 1970)
         * @param date
         * @returns {string}
         */
        getReadableDate(date) {
            return dayjs(date).format("DD MMMM YYYY");
        },
        getReadableDateTime(date) {
            return dayjs(date).format("DD MMMM YYYY HH:MM");
        },
        isSameMonth(date1, date2) {
            date1 = dayjs(date1);
            date2 = dayjs(date2);
            return (
                date1.year() === date2.year() &&
                date1.month() === date2.month()
            );
        }
    }
}